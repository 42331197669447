import Cookies from 'js-cookie'
import { passwords, cookieExpiryTimeInMins } from "@/config";


/**
 * Summary Logs user out.
 * Description logs a user out by removing passwords for login and review/amend sites
 * and then sends user to another page eg login page.
 * @param  {Object} context             You will usually pass 'this' as a value
 * @param  {string} pathAfterLogout
 */
export let processLogout = function(context, pathAfterLogout) {
    for (let pass of passwords) {
        context.$cookies.remove(pass.key);
    }
    context.$router.push({ path: pathAfterLogout });
};

export let processLogin = function(context, inputPassword) {
    console.log("pass input is", inputPassword);
    for (let pass of passwords) {
        if (pass.value === inputPassword) {
            console.log("pass is", pass);
            finalizeLogin(context, pass.key, inputPassword, pass.toRoute);
        }
    }

    context.error = true;
};

export let guardPage = function({ to, next }) {
    if (isPasswordInCookie(passwords)) {
        next();
    } else {
        let localeCode = to.query.lang != undefined ? to.query.lang : 'en';
        let localeURLQueryString = "?lang=" + localeCode;
        let nextUrl = "/login" + localeURLQueryString;
        console.log("the next url string is:", nextUrl)
        next(nextUrl);
    }
};

let finalizeLogin = function(
    context,
    passwordKey,
    inputPassword,
    pathAfterLogin
) {
    context.error = false;
    context.$cookies.set(passwordKey, inputPassword, cookieExpiryTimeInMins * 60);
    redirectAfterLogin(context, pathAfterLogin);
};

let redirectAfterLogin = function(context, pathAfterLogin) {
    context.$router.replace(pathAfterLogin);
};

let isPasswordInCookie = function(passwords) {
    for (let pass of passwords) {
        if (Cookies.get(pass.key) === pass.value) {
            return true;
        }
    }
    return false;
};