
import { createI18n } from 'vue-i18n'
import { supportedLocales, defaultLocale } from "@/config"
import { getPreferredLocales } from "@/common/utils"
import { localeList } from "@/common/locale-list"



let preferredLocales = getPreferredLocales(supportedLocales, localeList)

export const languages = Object.getOwnPropertyNames(loadLocaleMessages());

export let i18n = createI18n({
    globalInjection: true,
   // allowComposition: true,
    locale: process.env.VUE_APP_I18N_LOCALE || defaultLocale,
    fallbackLocale: false,
    silentTranslationWarn: true,
    messages: loadLocaleMessages()
})

export default i18n


function findLocalesInLocalesFolder() {
    const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.yml$/i)
    let localesInLocalesFolder = []
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            localesInLocalesFolder.push(locale)
        }
    })
    return localesInLocalesFolder
}

function loadLocaleMessages() {
   // console.log("locales in folder", findLocalesInLocalesFolder())
        //console.log("i was called")
    const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.yml$/i)
    const messages = {}
    locales.keys().forEach(key => {
            const matched = key.match(/([A-Za-z0-9-_]+)\./i)
            if (matched && matched.length > 1) {
                const locale = matched[1]
                    // console.log("locale is: " + locale)

                messages[locale] = locales(key)
            }
        })
       // console.log("messages are: " + JSON.stringify(messages));
    return messages
}



export function getpreferredLocales() {
    let annotatedLocales = []
    for (const code of Object.keys(preferredLocales)) {
        annotatedLocales.push({
            code,
            name: preferredLocales[code]
        })
    }
    return annotatedLocales
}

// ie is Locale In SupportedLocales?
export function isLocaleSupported(locale) {
    return Object.keys(preferredLocales).includes(locale)
}

export function isLocaleInLocalesFolder(locale) {
    return findLocalesInLocalesFolder().includes(locale)
}



/*
navigator.languages == ["ar-SA", "en-US"]
getBrowserLocale()                          // => "ar-SA"
getBrowserLocale({ countryCodeOnly: true }) // => "ar
*/
export function getBrowserLocale(options = {}) {
    const defaultOptions = { countryCodeOnly: false }
    const opt = {...defaultOptions, ...options }
    const navigatorLocale =
        navigator.languages !== undefined ?
        navigator.languages[0] :
        navigator.language
    if (!navigatorLocale) {
        return undefined
    }
    const trimmedLocale = opt.countryCodeOnly ?
        navigatorLocale.trim().split(/-|_/)[0] :
        navigatorLocale.trim()
    return trimmedLocale
}

export function configureLocaleUrl(context) {
    let input_lang = context.$route.query.lang;
    if (input_lang !== undefined) {
        if (isLocaleInLocalesFolder(input_lang)) {
            context.$i18n.locale = input_lang;
        } else {
            // context.$i18n.locale = defaultLocale;
            context.$router.push({
                    path: window.location.pathname,
                    query: { lang: defaultLocale },
                },
                () => {}
            ); // the second .push value "() => {}" prevents the error message on console
        }
    }
}


export function setCurrentLocale(localeKey) {
    i18n.locale = localeKey
}