<template>
  <div class="home">
    <!--
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/amend">Amend</router-link>
    </div>
    -->
    <router-view />
  </div>
</template>

<script>


export default {
  //name: "Index",
};
</script>
