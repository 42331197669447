import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '@/views/LoginPage.vue';
import IndexPage from '@/views/IndexPage.vue';
import HomePage from '@/views/HomePage.vue';
import AmendPage from '@/views/AmendPage.vue';
import NotFound from '@/views/NotFound.vue';
import { guardPage } from '@/plugins/auth';
import middlewarePipeline from '@/middleware/middlewarePipeline';

const isLoginPageEnabled = process.env.VUE_APP_IS_LOGIN_ENABLED;

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      // requiresAuth: false
    },
  },
  {
    path: '/',
    component: IndexPage,
    children: [
      {
        // HomePage will be rendered inside IndexPage's <router-view>
        // when / is matched
        path: '',
        component: HomePage,
        meta: {
          middleware: [guardPage],
        },
      },
      {
        path: 'amend',
        component: AmendPage,
        meta: {
          middleware: [guardPage],
        },
      },
    ],
  },
  { path: '/:catchAll(.*)', component: NotFound },
];

// Create the router instance
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Middleware handling for routes
if (isLoginPageEnabled === 'true') {
  router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
      return next();
    }
    const middleware = to.meta.middleware;

    const context = {
      to,
      // from,
      next,
      // store
    };
    return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1),
    });
  });
}

export default router;
