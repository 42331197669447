
<template>
  <consent-layout @dropdownLocaleChangeEvent="reloadVideos()" >
                <cn-video v-if="videos[0].imageSrc" style="margin-bottom: 64px"  :videoImage="videos[0].imageSrc" :video="$t('video_one')" :videoTitle="$t('video_caption_one')" class=""></cn-video>
                <cn-video v-if="videos[1].imageSrc" style="margin-bottom: 64px"  :videoImage="videos[1].imageSrc" :video="$t('video_two')" :videoTitle="$t('video_caption_two')" class="js-scroll"></cn-video>
                <cn-video v-if="videos[2].imageSrc" style="margin-bottom: 64px"  :videoImage="videos[2].imageSrc"  :video="$t('video_three')" :videoTitle="$t('video_caption_three')" class="js-scroll"></cn-video>
                <cn-video v-if="videos[3].imageSrc" style="margin-bottom: 64px"  :videoImage="videos[3].imageSrc" :video="$t('video_four')" :videoTitle="$t('video_caption_four')" class="js-scroll"></cn-video>
                <cn-video v-if="videos[4].imageSrc" style="margin-bottom: 64px"  :videoImage="videos[4].imageSrc" :video="$t('video_five')" :videoTitle="$t('video_caption_five')" class="js-scroll"></cn-video>
  </consent-layout>
</template>



<script>
import mixin from '@/common/mixin';

export default {
  mixins: [mixin]
};
</script>
