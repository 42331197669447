import { createApp } from 'vue'
import App from '@/App.vue'
import i18n from '@/plugins/i18n'
import router from '@/routes'
import VueCookies from 'vue-cookies'
//import StoragePlugin from 'vue-web-storage';
import '@/pendo.js';


const $app = createApp(App)

$app.use(i18n)
$app.use(router)
$app.use(VueCookies);
/*
$app.use(StoragePlugin, {
    prefix: '',
    drivers: ['session', 'local'], // default 'local'
});
*/

//$app.config.productionTip = false;

router.isReady().then(() => {
    $app.mount('#app')
})