<template>
<div id="app">
    <!-- class="animate" -->
    <router-view />
</div>
</template>

<script>
import {
    configureLocaleUrl
} from "@/plugins/i18n";
import utils from "@/common/utils";
import consent from "@/common/consent";

export default {
    components: {},
     created(){
        utils.configureConsent(consent);
        configureLocaleUrl(this);
    },
    mounted() {
        utils.configurePageAnimation();
    },
    unmounted() {
    },
    methods: {},
};
</script>

<style lang="scss">
@import "@/assets/styles/app.scss";
</style>
